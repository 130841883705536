import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DataService } from './data-service';
import { RecentReviewListItem } from '../models/most-recent-reviewlist-item';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WatchlistService {
    mostRecentCollectionId: string = `HOAny1ZmxRkrLegp8ccU`;
    collectionName: string = `most-recent-watchlist`;
    watchlistSubcollectionName: string = `user-recent-watchlist-view`;
    recentReviewlistCollectionName: string = 'most-recent-reviewlist';
    usersCollectionName: string = 'users';
    
    constructor(
      private afs: AngularFirestore,
      private dataService: DataService,
    ) { }

    updateUserWatchlistRecord(uid, claims) {
      let watchlistDocId = undefined;
      let payload = {};
      const userWatchlistPath = `${this.collectionName}/${uid}/${this.watchlistSubcollectionName}`;
      return this.afs.collection(userWatchlistPath).get().pipe(
        map(snapshot => {
          if (snapshot.empty) {
            payload = this.generateCreatePayload(Array.from(new Set(claims)));
            payload['uid'] = uid;
            return;
          } else {
            return snapshot.docs.map(doc => {
              watchlistDocId = doc.id;
              let userWatchlistRec = doc.data();
              const finalClaims = Array.from(new Set([...claims, ...userWatchlistRec['claims']]));
              payload = this.generateUpdatePayload(finalClaims);
              return userWatchlistRec;
              });
          }
        }),
        tap(data => {
          this.afs
          .collection(userWatchlistPath)
          .doc(watchlistDocId)
          .set(payload, { merge: true }  
          );
        }),
      );
    }

    generateCreatePayload(claims) {
      return {
        'claims':claims,
        'updatedAt': this.dataService.getTimestamp(),
        'createdAt': this.dataService.getTimestamp(),
        'watchlistViewName':'userCurrentView',
      }
    }

    generateUpdatePayload(claims) {
      return {
        'claims':claims,
        'updatedAt': this.dataService.getTimestamp(),
      }
    }
    
    // find reviewlist items added by super manager from watchlist ids
    getRecentReviewlistBySuperManager(watchlistIds: Array<string>, superManangerUid: string): Observable<RecentReviewListItem[]> {
        const collectionName = this.recentReviewlistCollectionName;
        return this.afs.collection(collectionName,
            ref => ref
                .where('claim', 'in', watchlistIds)
                .where('reviewProcessStartedBy', '==', superManangerUid)
            )
            .valueChanges()
            .pipe(
                map(result => result.map(reviewlist => this.dataService.formatRecentReviewItem(reviewlist)))
            )
    }

    // find reviewlist items from watchlist ids
    getRecentReviewlistByWatchlistIds(watchlistIds: Array<string>): Observable<RecentReviewListItem[]> {
        const collectionName = this.recentReviewlistCollectionName;
        return this.afs.collection(collectionName,
            ref => ref
                .where('claim', 'in', watchlistIds)
                .orderBy('createdAt','asc')
            )
            .valueChanges()
            .pipe(
                map(result => result.map(reviewlist => this.dataService.formatRecentReviewItem(reviewlist)))
            )
    }

    // get recent reviewlist items added by super manager from current user's watchlist
    getRecentReviewlistFromSuperManager(watchlistIds: Array<string>): Observable<RecentReviewListItem[]> {
        const reviewlistTemp = [];
        const reviewlist = this.getSuperManangerUsers()
            .pipe(
                switchMap(users => {
                    const superManagerUids = users.map(d => d['uid']);
                    superManagerUids.forEach(superManangerUid => {
                        reviewlistTemp.push(this.getRecentReviewlistBySuperManager(watchlistIds, superManangerUid))
                    });
                    const superManangerReviewlist = combineLatest(reviewlistTemp).pipe(map(d => [].concat.apply([], d)));
                    return superManangerReviewlist;
                }),
            )
        return reviewlist
            .pipe(
                map(recent => recent as RecentReviewListItem[])
            );
    }

    // get recent reviewlist items from current user's watchlist
    getRecentReviewlistFromUserWatchlist(watchlistIds: Array<string>): Observable<RecentReviewListItem[]> {
        return this.getRecentReviewlistByWatchlistIds(watchlistIds)
            .pipe(
                map(recent => recent as RecentReviewListItem[])
            )             
    }

    // get a list of super managers
    getSuperManangerUsers() {
        const collectionName = this.usersCollectionName;
        return this.afs.collection(collectionName, ref => ref.where('roles.superManager', '==', true)).valueChanges();
    }

    getWatchlistBasedOnClaims(claimNumber) {
      return this.afs.collectionGroup(this.watchlistSubcollectionName, ref => ref.where(`claims`,'array-contains',claimNumber)).get();
    }

    getAndUpdateUserWatchlistByClaim(claimNumber) {
      let payload = {};
      let recId = ``;
      let userWatchlistPath = ``;
      return this.getWatchlistBasedOnClaims(claimNumber).pipe(
          switchMap((record) => {
            record.docs.map((rec) =>{
              recId = rec.id;
              let userWatclishRecord = rec.data();
              const claims = userWatclishRecord[`claims`].filter(item =>item !== claimNumber);
              userWatchlistPath = `${this.collectionName}/${userWatclishRecord[`uid`]}/${this.watchlistSubcollectionName}`;
                payload = {
                  claims:claims,
                  'updatedAt':this.dataService.getTimestamp()
                }
                this.afs
                .collection(userWatchlistPath)
                .doc(recId)
                .set(payload, { merge: true }  
                );
            });
            return of(record);
          })
        )
    }

}
